


import { Options, Vue } from 'vue-class-component';
import Background from './Background.vue';

@Options({
  components: {
    Background,
  },
  props:['userData'],
  computed:{
    site() {
        return {
            url:window.location.origin,
            name:window.location.host,
        }
    }
  }
})

export default class LeftSide extends Vue {
    

}


