import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createVNode as _createVNode } from "vue"
import _imports_0 from '../assets/images/photo.jpeg'


const _hoisted_1 = { class: "lang-selector-wrapper" }
const _hoisted_2 = { class: "lang-selector" }
const _hoisted_3 = { class: "hexagon" }
const _hoisted_4 = ["alt"]
const _hoisted_5 = { class: "cv-name text-center" }
const _hoisted_6 = { class: "cv-position text-center" }
const _hoisted_7 = { class: "cv-contakts-container text-center" }
const _hoisted_8 = { class: "cv-contakts" }
const _hoisted_9 = { class: "cv-contakt" }
const _hoisted_10 = ["href"]
const _hoisted_11 = { class: "contakt-text" }
const _hoisted_12 = { class: "cv-description-container" }
const _hoisted_13 = { class: "cv-description row" }
const _hoisted_14 = { class: "col-md-4 cv-description-label" }
const _hoisted_15 = { class: "col-md-8 cv-description-text" }
const _hoisted_16 = { class: "cv-description row" }
const _hoisted_17 = { class: "col-md-8 cv-description-text" }
const _hoisted_18 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_RouterLink = _resolveComponent("RouterLink")!
  const _component_Background = _resolveComponent("Background")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        (_openBlock(), _createBlock(_component_router_link, {
          to: "/?lang=ua",
          key: 1,
          class: "lang-selector-item",
          title: "Українська"
        }, {
          default: _withCtx(() => _cache[0] || (_cache[0] = [
            _createElementVNode("div", { class: "flag flag-ua" }, null, -1),
            _createElementVNode("div", { class: "lang-name" }, "Українська", -1)
          ])),
          _: 1
        })),
        (_openBlock(), _createBlock(_component_RouterLink, {
          to: "/?lang=de",
          key: 2,
          class: "lang-selector-item",
          title: "Deutsch"
        }, {
          default: _withCtx(() => _cache[1] || (_cache[1] = [
            _createElementVNode("div", { class: "flag flag-de" }, null, -1),
            _createElementVNode("div", { class: "lang-name" }, "Deutsch", -1)
          ])),
          _: 1
        })),
        (_openBlock(), _createBlock(_component_RouterLink, {
          to: "/?lang=en",
          key: 3,
          class: "lang-selector-item",
          title: "English"
        }, {
          default: _withCtx(() => _cache[2] || (_cache[2] = [
            _createElementVNode("div", { class: "flag flag-en" }, null, -1),
            _createElementVNode("div", { class: "lang-name" }, "English", -1)
          ])),
          _: 1
        }))
      ])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("img", {
        src: _imports_0,
        alt: _ctx.userData.name,
        class: "hexagon-img"
      }, null, 8, _hoisted_4)
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("h3", null, _toDisplayString(_ctx.userData.name), 1)
    ]),
    _createElementVNode("p", _hoisted_6, _toDisplayString(_ctx.userData.position), 1),
    _cache[4] || (_cache[4] = _createElementVNode("hr", null, null, -1)),
    _createElementVNode("div", _hoisted_7, [
      _createElementVNode("ul", _hoisted_8, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.userData.contakts, (contakt) => {
          return (_openBlock(), _createElementBlock("li", _hoisted_9, [
            _createElementVNode("a", {
              href: contakt.href,
              target: "_blank"
            }, [
              _createElementVNode("span", _hoisted_11, _toDisplayString(contakt.title), 1),
              _createElementVNode("div", {
                class: _normalizeClass([contakt.typeLogoClassName, "btn-bg-image contakt-image-telegram"])
              }, null, 2)
            ], 8, _hoisted_10)
          ]))
        }), 256))
      ])
    ]),
    _createElementVNode("div", _hoisted_12, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.userData.leftSideDescriptions, (description) => {
        return (_openBlock(), _createElementBlock("div", _hoisted_13, [
          _createElementVNode("div", _hoisted_14, _toDisplayString(description.label), 1),
          _createElementVNode("div", _hoisted_15, _toDisplayString(description.text), 1)
        ]))
      }), 256)),
      _createElementVNode("div", _hoisted_16, [
        _cache[3] || (_cache[3] = _createElementVNode("div", { class: "col-md-4 cv-description-label" }, "Site:", -1)),
        _createElementVNode("div", _hoisted_17, [
          _createElementVNode("a", {
            href: _ctx.site.url
          }, _toDisplayString(_ctx.site.name), 9, _hoisted_18)
        ])
      ])
    ]),
    _createVNode(_component_Background, { typeClass: "left-side" })
  ]))
}