import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "cv-texts-wrapper" }
const _hoisted_2 = { class: "cv-texts" }
const _hoisted_3 = {
  key: 0,
  class: "cv-text-header"
}
const _hoisted_4 = { class: "cv-text-block" }
const _hoisted_5 = {
  key: 0,
  class: "cv-text-block-label"
}
const _hoisted_6 = { key: 0 }
const _hoisted_7 = ["innerHTML"]
const _hoisted_8 = ["innerHTML"]
const _hoisted_9 = ["innerHTML"]
const _hoisted_10 = {
  key: 1,
  class: "cv-description-list"
}
const _hoisted_11 = { key: 1 }
const _hoisted_12 = { class: "row" }
const _hoisted_13 = ["innerHTML"]
const _hoisted_14 = ["innerHTML"]
const _hoisted_15 = ["innerHTML"]
const _hoisted_16 = { key: 2 }
const _hoisted_17 = {
  key: 0,
  class: "cv-title0"
}
const _hoisted_18 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        (_ctx.section.header)
          ? (_openBlock(), _createElementBlock("h2", _hoisted_3, _toDisplayString(_ctx.section.header), 1))
          : _createCommentVNode("", true),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.section.blocks, (block) => {
          return (_openBlock(), _createElementBlock("div", _hoisted_4, [
            (block.label)
              ? (_openBlock(), _createElementBlock("h4", _hoisted_5, _toDisplayString(block.label), 1))
              : _createCommentVNode("", true),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(block.subBlocks, (subBlock) => {
              return (_openBlock(), _createElementBlock("div", {
                class: _normalizeClass([{ 'next-paragraph': !(subBlock.title || subBlock.title1) }, "cv-text-subblock"])
              }, [
                ('list' == subBlock.type)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                      _createElementVNode("span", null, [
                        (subBlock.title0)
                          ? (_openBlock(), _createElementBlock("span", {
                              key: 0,
                              class: "cv-title0",
                              innerHTML: subBlock.title0
                            }, null, 8, _hoisted_7))
                          : _createCommentVNode("", true),
                        (subBlock.title1)
                          ? (_openBlock(), _createElementBlock("span", {
                              key: 1,
                              class: "cv-title1",
                              innerHTML: subBlock.title1
                            }, null, 8, _hoisted_8))
                          : _createCommentVNode("", true)
                      ]),
                      (subBlock.description)
                        ? (_openBlock(), _createElementBlock("div", {
                            key: 0,
                            innerHTML: subBlock.description,
                            class: "cv-description"
                          }, null, 8, _hoisted_9))
                        : _createCommentVNode("", true),
                      (subBlock.list)
                        ? (_openBlock(), _createElementBlock("ul", _hoisted_10, [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(subBlock.list, (listItem) => {
                              return (_openBlock(), _createElementBlock("li", null, _toDisplayString(listItem), 1))
                            }), 256))
                          ]))
                        : _createCommentVNode("", true)
                    ]))
                  : _createCommentVNode("", true),
                ('list-col' == subBlock.type)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                      _createElementVNode("div", _hoisted_12, [
                        (subBlock.title0)
                          ? (_openBlock(), _createElementBlock("div", {
                              key: 0,
                              class: "cv-title0 col-4",
                              innerHTML: subBlock.title0
                            }, null, 8, _hoisted_13))
                          : _createCommentVNode("", true),
                        (subBlock.title1)
                          ? (_openBlock(), _createElementBlock("div", {
                              key: 1,
                              class: "cv-title1 col-8",
                              innerHTML: subBlock.title1
                            }, null, 8, _hoisted_14))
                          : _createCommentVNode("", true)
                      ]),
                      (subBlock.description)
                        ? (_openBlock(), _createElementBlock("div", {
                            key: 0,
                            innerHTML: subBlock.description,
                            class: "row cv-description"
                          }, null, 8, _hoisted_15))
                        : _createCommentVNode("", true)
                    ]))
                  : _createCommentVNode("", true),
                ('text' == subBlock.type)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
                      (subBlock.title)
                        ? (_openBlock(), _createElementBlock("span", _hoisted_17, _toDisplayString(subBlock.title), 1))
                        : _createCommentVNode("", true),
                      (subBlock.description)
                        ? (_openBlock(), _createElementBlock("p", {
                            key: 1,
                            innerHTML: subBlock.description,
                            class: "cv-description"
                          }, null, 8, _hoisted_18))
                        : _createCommentVNode("", true)
                    ]))
                  : _createCommentVNode("", true)
              ], 2))
            }), 256))
          ]))
        }), 256))
      ])
    ])
  ]))
}