
import { Options, Vue } from 'vue-class-component';

    @Options({
        props:['typeClass'],
    })
    export default class App extends Vue {
        
        getViewBox = () => {
            let viewBoxes = {
                'left-side': '0 0 300 300',
                'right-side': '0 0 500 600',
            }
            return viewBoxes[this.typeClass];
        };

        getHexagon0Transform = () => {
            let hexagon0Transforms = {
                'left-side': 'matrix(1.5456759,0,0,1.5456759,86.624851,183.25944)',
                'right-side': 'translate(298.43704,69.446904)',
            }
            return hexagon0Transforms[this.typeClass];
        };
        getHexagon1Transform = () => {
            let hexagon1Transforms = {
                'left-side': 'translate(174,68)',
                'right-side': 'matrix(1.5456759,0,0,1.5456759,413.6339,501.58671)',
            }
            return hexagon1Transforms[this.typeClass];
        };
        getHexagon2Transform = () => {
            let hexagon2Transforms = {
                'left-side': 'matrix(0.69010562,0,0,0.69010562,216.88549,157.40634)',
                'right-side': 'matrix(0.69010562,0,0,0.69010562,41.805424,291.97196)',
            }
            return hexagon2Transforms[this.typeClass];
        };
    }
