


import { Options, Vue } from 'vue-class-component';
//import Background from './Background.vue';

@Options({
  components: {
    //Background,
  },
  props:['section'],
})

export default class LeftSide extends Vue {
    

}


