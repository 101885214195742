import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, createBlock as _createBlock, normalizeClass as _normalizeClass, createVNode as _createVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "cv-loader-container"
}
const _hoisted_2 = {
  key: 1,
  class: "cv-main-container cv-theme",
  style: {"display":"block"}
}
const _hoisted_3 = { class: "container" }
const _hoisted_4 = { class: "fixed-menu no-print" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LeftSide = _resolveComponent("LeftSide")!
  const _component_RightSide = _resolveComponent("RightSide")!
  const _component_Background = _resolveComponent("Background")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (!_ctx.userData)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, _cache[1] || (_cache[1] = [
          _createElementVNode("div", { class: "cv-loader" }, null, -1)
        ])))
      : _createCommentVNode("", true),
    (_ctx.userData)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.userData.sections, (section, sectionI) => {
              return (_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(`row section-container section-container-page${sectionI}`)
              }, [
                _createElementVNode("div", {
                  class: _normalizeClass([sectionI?'no-print':'', "cv-page-left col-md-4 col-sm-4"])
                }, [
                  (!sectionI)
                    ? (_openBlock(), _createBlock(_component_LeftSide, {
                        key: 0,
                        userData: _ctx.userData
                      }, null, 8, ["userData"]))
                    : _createCommentVNode("", true)
                ], 2),
                _createElementVNode("div", {
                  class: _normalizeClass([sectionI?'col-print-12':'', "right-side cv-page-right col-md-8 col-sm-8"])
                }, [
                  _createVNode(_component_RightSide, { section: section }, null, 8, ["section"])
                ], 2)
              ], 2))
            }), 256)),
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("button", {
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.printPage())),
                class: "btn-bg-image btn-image-print"
              })
            ]),
            _createVNode(_component_Background, { typeClass: "right-side" }),
            _cache[2] || (_cache[2] = _createElementVNode("div", { class: "bg" }, null, -1))
          ])
        ]))
      : _createCommentVNode("", true)
  ], 64))
}